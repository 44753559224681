import React, { useEffect, useState } from 'react';
import {graphql, PageProps} from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { H1 ,H2,P, Ul} from '../styles/Fonts';
import { Container, Description, Image, MainStyled,MVVBlockStyled ,SectionDescriptionStyled, HorizontalBar } from '../styles/pages/about-page.style';
import CardAbout from '../components/CardAbout';
import Navbar from '../components/NavBar';
import { Logo } from '../components/Layout';
import {MissionIcon,BalanceIcon,ValuesIcon} from '../components/icons';


export interface PageAboutProps {
    markdownRemark :{
        frontmatter : AboutBaseProps;
    }
}

export interface AboutBaseProps{
    titleSEO : string;
    descriptionSEO: string;
    description: string;
    mission : string;
    eyesight : string;
    values : Array<string>;
}
interface AboutProps extends AboutBaseProps  {
    location : {pathname : string;};
    preview? : boolean;
}
export  function PageAboutTemplate({ titleSEO,descriptionSEO ,description,mission,eyesight,values, location, preview  } : AboutProps ){
    const CardsKeys = [1,2,3]
   
  const [stateCard, activeCard] = useState<number>(1);

return (
    <>
    {!preview?(<SEO title={titleSEO} description={descriptionSEO} pathname={location.pathname} />) :''}
		<Container>
            <MainStyled>  
     
                <h1 hidden>{titleSEO}</h1>     
                <SectionDescriptionStyled>
                    <H2 size="S3" color="primary">{description} </H2>
                </SectionDescriptionStyled>
                <HorizontalBar/>
                <MVVBlockStyled >
                    <CardAbout Icon={MissionIcon} id={CardsKeys[0]} 
                    description={mission}
                    title="Nossa Missão"
                    state ={stateCard}
                    toggle = {activeCard}
                    />
                    <CardAbout Icon={BalanceIcon} id={CardsKeys[1]} 
                    description={eyesight}
                    title="Nossa visão"
                    state ={stateCard}
                    toggle = {activeCard}
                    />
                    <CardAbout Icon={ValuesIcon} id={CardsKeys[2]} 
                    description={values}
                    title="Nossos valores"
                    state ={stateCard}
                    toggle = {activeCard}
                    />
                </MVVBlockStyled>
            </MainStyled>
            <Logo />
		</Container>
        </>
        );}

const  PageAbout = ({data,location} :PageProps<PageAboutProps>)=>{
    const content = data.markdownRemark.frontmatter;

 return(
     <PageAboutTemplate
     titleSEO={content.titleSEO}
     descriptionSEO={content.descriptionSEO}
     description={content.description}
     location={location}
     eyesight={content.eyesight}
     mission={content.mission}
     values={content.values}
     />
 );
 }
export default PageAbout;

export const query = graphql`
    query AboutPageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
        frontmatter {
            titleSEO 
            descriptionSEO
            description 
            mission 
            eyesight 
            values 
        }
    }
    }
`;