import React from "react";
import styled from 'styled-components';

import LogoIcon from '../../assets/icons/coloredLogo.png';
import { P, Ul } from "../Fonts";
import gavel from '../../assets/images/gavelX2.png';




import media from '../utils/media'

export const Container = styled.div`
    display: flex;
 
    ${media.sm`
        min-height: 100vh;
        display: flex;
        margin-left : 5.0rem;
        font-size: 100%;
        background-image: url(${gavel});
        background-repeat: no-repeat;
        background-position: center -5rem; 
         background-size: 40rem;
        
       
    `}
    ${media.md`
         background-position: right center; 
         background-size: 50%;
    `}

     


    

`;

export const MainStyled = styled.main`
    display:grid;
   // width: 100%;
    gap: 40px;
    height:100%;
    
    align-items: center;
    ${media.sm`
        min-height:100vh;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;    
        display:flex;
        flex-direction: column;
        padding-bottom: 1rem;
        flex:1;
    `}
    
    ${media.md`
        display:grid;
        grid-template-columns: 1fr 20px 1fr ;
        grid-template-rows: 1fr;
    `}
    ${media.lg`
        font-size:125%;
    `}

`;


export const MVVBlockStyled = styled.div`
 

    grid-column-start: 1;
    grid-row-start: 2;
    width: 90%;
    margin: 0 auto ;
    font-size: 125%;

    
    ${media.sm`
        max-width: 50.0rem;
        min-width: 32.0rem;
        grid-column-start: 1;
        grid-row-start: 2; 

        margin-bottom: 2rem;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-row-start: 1;
    `}
`;

export const HorizontalBar = styled.div`
    display:none;    
    ${media.sm`
      
    `}
    ${media.md`
        display:block;
        padding: 0;
        border-left: 2px solid #ccc;
        width: 0px;
        height: 500px;
        margin-left: -1.5rem;

 
       
    `}
    ${media.lg`
        margin-left: 3rem;
    `}


`
export const SectionDescriptionStyled = styled.div`
    font-size: 40%;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;

    border-left: 2px solid ${(props)=>props.theme.colors.primary};
    padding-left: 2rem;

 
    ${media.sm`
        padding-left: 0rem;
        border-left: 0;
        
        max-width: 50.0rem;
        min-width: 25.0rem;
        grid-column-start: 1;
        grid-row-start: 1;
        font-size:70%;
        margin-top: 2rem;
        margin-right : 1rem;
        text-align : justify;
        
    `}
    ${media.md`
        margin-top: 0rem;
        margin-left : -3rem;
        grid-column-start: 3;
        grid-row-start: 1;  
    `}
    ${media.lg`
        margin-left : 0rem;
        max-width: 50.0rem;
        margin-right: 2rem;
      
    `}
`;



export const Description = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    margin-left: 115px;
`;

export const Image = styled.div`
    position: absolute;
    width: 443px;
    height: 100%;
    left: 55%;


`;

