import { link } from 'fs';
import { number } from 'prop-types';
import React, { useState } from 'react'
import {InterpolationChain, useSpring} from 'react-spring';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../Hooks/useMediaQuery';
import { H2 } from '../../styles/Fonts';
import { PStyled, SectionStyled , UlStyled} from './styles';
interface CardProps{
  id :number;
  title : string;
  description : string | string[];
  state : number ;
  toggle : (number : number)=>void;
  Icon : ({ invertColor }: { invertColor: boolean; }) => JSX.Element
}

export interface InterpolateX {
  interpolate: InterpolationChain<unknown>;
  getValue: () => unknown;
}
export const animationProps = (x :InterpolateX)=>({
  opacity: x.interpolate({ range: [0, 1], output: [0, 1] }),
 
  lineHeight: x
    .interpolate(x => `${(x as number) *100}%`),
  padding : x
  .interpolate(x => `${x}rem ${(x as number) * 2}rem`),

})



export default function CardAbout({id ,title,description,state,toggle,Icon} : CardProps){
    const animationSection = (number :number)=>{
      const { x } = useSpring({ from: { x: 0 }, x: state ===  number? 1 : 0, config: { duration: 500 } });
      return x;

    };
    const theme  = useTheme();
    const isXsm = useMediaQuery(`(max-width : ${theme.breakpoints.sm}px)`)

    const animation = animationSection(id);
 
      return(
        <SectionStyled  onClick={()=>toggle(id)}>
        <H2 size="S6" color="primary" ><Icon invertColor={state ===id} />{title}:</H2>
        {Array.isArray(description)?
        (<UlStyled style={isXsm?animationProps(animation): {}}  size="SP" color="textP">
           {description.map((desc=>( <li>{desc}</li> )))}
        </UlStyled>)
        :
        (<PStyled  style={isXsm?animationProps(animation): {}}   size="SP" color="textP">{description}</PStyled>)
        
        }
        </SectionStyled  >
      )

}