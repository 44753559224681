import styled from 'styled-components';

import {animated} from 'react-spring';
import { P, Ul } from '../../styles/Fonts';
import media from '../../styles/utils/media';


export const SectionStyled = styled(animated.section)`
    margin-top: 10px;

    h2{
        margin-bottom: 10px;
        display :flex;
        align-items: center;
        svg{
            margin-right: 10px;
        }
        
    }
`;

export const  PStyled = styled(animated(P))`  

    border : 1px solid ${props =>props.theme.colors.textP};
    border-radius: 8px;

    ${media.sm`
        padding : 1rem 2rem;
    `}
`;

export const UlStyled = styled(animated(Ul))`
    list-style : inside "- ";

    line-height: 2.4rem;

    ${media.sm`
        padding : 1rem 2rem;
    `}
                                                 


    border : 1px solid ${props =>props.theme.colors.textP};
    border-radius: 8px;
    font-weight : 300;

`;