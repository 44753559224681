

import React from 'react';

export const MissionIcon  = ({invertColor} :{invertColor : boolean}) => {
    const  primaryColor = "#000F6E";
    const  secondaryColor = "#FCFCFC";
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill={invertColor?primaryColor:secondaryColor} xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17" fill={invertColor?primaryColor:secondaryColor} stroke={invertColor?secondaryColor:primaryColor}/>
        <g clip-path="url(#clip0)">
        <path d="M8.83838 25.3076H19.4444V27.1025H8.83838V25.3076ZM12.5859 13.7038L15.0871 11.164L27.5846 23.8538L25.0833 26.3935L12.5859 13.7038ZM18.8434 7.35889L23.846 12.4384L21.3447 14.9781L16.3422 9.89863L18.8434 7.35889ZM11.3396 14.9691L16.3422 20.0486L13.8409 22.5884L8.83838 17.5089L11.3396 14.9691Z" fill={invertColor?secondaryColor:primaryColor}/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="21.2121" height="21.5385" fill="white" transform="translate(7.95459 6.46143)"/>
        </clipPath>
        </defs>
        </svg>
    )
}
export const BalanceIcon  = ({invertColor} :{invertColor : boolean}) => {
    const  primaryColor = "#000F6E";
    const  secondaryColor = "#FCFCFC";
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill={invertColor?secondaryColor:primaryColor} xmlns="http://www.w3.org/2000/svg">
        <g id="Group 48">
        <rect id="Rectangle 6" x="0.5" y="0.5" width="34" height="34" rx="17" fill={invertColor?primaryColor:secondaryColor} stroke={invertColor?secondaryColor:primaryColor}/>
        <g id="fa:balance-scale">
        <g id="cil:balance-scale" clip-path="url(#clip0)">
        <path id="Vector" d="M15.8448 20.7818L16.0417 20.5651V19.4969L12.8153 12.6858H11.3135L8.08716 19.4969V20.6172L8.26257 20.8076C8.74436 21.3293 9.32879 21.7457 9.97918 22.0308C10.6296 22.3158 11.3319 22.4633 12.042 22.464H12.0594C12.7724 22.4609 13.477 22.3097 14.1286 22.0202C14.7802 21.7306 15.3646 21.3089 15.8448 20.7818ZM14.7159 20.0441C14.0087 20.7466 13.0517 21.1401 12.0549 21.1384H12.0412C11.0618 21.1433 10.1191 20.7665 9.41292 20.088V19.7952L12.0644 14.1975L14.7159 19.7952V20.0441Z" fill={invertColor?secondaryColor:primaryColor}/>
        <path id="Vector_2" d="M24.7472 12.6858H23.2454L20.019 19.4969V20.6172L20.1945 20.8076C20.6762 21.3293 21.2607 21.7457 21.9111 22.0308C22.5615 22.3158 23.2637 22.4633 23.9739 22.464H23.9913C24.7043 22.4609 25.4089 22.3097 26.0605 22.0201C26.7121 21.7305 27.2965 21.3088 27.7767 20.7817L27.9736 20.5651V19.4969L24.7472 12.6858ZM26.6478 20.0441C25.9405 20.7466 24.9836 21.1401 23.9867 21.1384H23.9731C22.9937 21.1433 22.051 20.7665 21.3448 20.088V19.7952L23.9963 14.1975L26.6478 19.7952V20.0441Z" fill={invertColor?secondaryColor:primaryColor}/>
        <path id="Vector_3" d="M18.6933 12.9201C19.062 12.8093 19.3976 12.6088 19.6698 12.3365C19.9421 12.0642 20.1426 11.7287 20.2535 11.3599H24.6592V10.0342H20.2535C20.1108 9.55537 19.8174 9.13544 19.4168 8.83684C19.0163 8.53825 18.53 8.37695 18.0304 8.37695C17.5308 8.37695 17.0445 8.53825 16.644 8.83684C16.2434 9.13544 15.95 9.55537 15.8073 10.0342H11.4016V11.3599H15.8073C15.9182 11.7287 16.1187 12.0642 16.391 12.3365C16.6632 12.6088 16.9988 12.8093 17.3675 12.9201V23.9546H13.0588V25.2804H23.002V23.9546H18.6933V12.9201ZM17.0361 10.697C17.0361 10.5004 17.0944 10.3081 17.2037 10.1446C17.3129 9.98111 17.4682 9.85367 17.6499 9.77841C17.8316 9.70315 18.0315 9.68346 18.2244 9.72183C18.4173 9.76019 18.5944 9.85489 18.7335 9.99395C18.8725 10.133 18.9672 10.3102 19.0056 10.5031C19.044 10.6959 19.0243 10.8959 18.949 11.0775C18.8738 11.2592 18.7463 11.4145 18.5828 11.5238C18.4193 11.633 18.2271 11.6914 18.0304 11.6914C17.7667 11.6914 17.5138 11.5866 17.3273 11.4001C17.1408 11.2137 17.0361 10.9607 17.0361 10.697Z" fill={invertColor?secondaryColor:primaryColor}/>
        </g>
        </g>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="21.2121" height="21.2121" fill="white" transform="translate(7.42432 4.77271)"/>
        </clipPath>
        </defs>
        </svg>
        
    )
}

export const ValuesIcon  = ({invertColor} :{invertColor : boolean}) => {
    const  primaryColor = "#000F6E";
    const  secondaryColor = "#FCFCFC";
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill={invertColor?primaryColor:secondaryColor} xmlns="http://www.w3.org/2000/svg">
        <g id="Group 51">
        <rect id="Rectangle 5" x="0.5" y="0.5" width="34" height="34" rx="17" fill={invertColor?primaryColor:secondaryColor} stroke={invertColor?secondaryColor:primaryColor}/>
        <g id="Group 9">
        <g id="account_balance-black-48dp 1">
        <rect width="21.2121" height="21.2121" transform="translate(6.89404 6.89404)" fill={invertColor?primaryColor:secondaryColor}/>
        <path id="Vector" d="M12.6391 15.7325H10.8715V21.9194H12.6391V15.7325ZM17.9422 15.7325H16.1745V21.9194H17.9422V15.7325ZM25.4548 23.687H8.66187V25.4547H25.4548V23.687ZM23.2452 15.7325H21.4775V21.9194H23.2452V15.7325ZM17.0583 9.77543L21.6631 12.1971H12.4535L17.0583 9.77543ZM17.0583 7.77795L8.66187 12.1971V13.9648H25.4548V12.1971L17.0583 7.77795Z" fill={invertColor?secondaryColor:primaryColor}/>
        </g>
        </g>
        </g>
        </svg>
        
        
    )
}



